import { IRegisterElement, IRegisterSelect, IUseForm } from './types';
import { Select } from '@cue/atoms';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode, createElement } from 'react';
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  Resolver,
  useForm as reactHookUseForm,
} from 'react-hook-form';
import * as yup from 'yup';

export function useForm<T extends FieldValues>(props: IUseForm<T> = {}) {
  const { schema, defaultValues, resolver, mode } = props;
  const form = reactHookUseForm<T>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore // TODO
    defaultValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore // TODO
    resolver: resolver ? (resolver as Resolver<T>) : schema ? yupResolver(schema) : undefined,
    mode,
  });

  function registerElement<U>({ element, name, props }: IRegisterElement<T, U>): ReactNode {
    return createElement(element, { ...form.register(name), ...props });
  }

  function registerSelect({ name, props }: IRegisterSelect<T>) {
    return (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              {...props}
              value={value}
              defaultValue={value}
              onChange={(e) => {
                onChange(e as PathValue<T, Path<T>>);
              }}
            />
          );
        }}
      />
    );
  }

  const isValidEmail = (email: string) => {
    return yup.string().email().isValidSync(email);
  };

  const isValidUrl = (url: string) => {
    return yup.string().url().isValidSync(url);
  };

  return { form, registerElement, registerSelect, isValidEmail, isValidUrl };
}