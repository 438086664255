import { AdhocExpertsProps } from './adhoc-experts';
import { parseGatsbyImage } from '@project/cms/pre-hooks';
import { graphql, useStaticQuery } from 'gatsby';

type UseAdhocSettingsReturn  = {
  id: string;
  adhocTimesFlag: string | null | undefined;
  topics: { id: string; title: string; slug: string }[];
  languages: { title: string; translations: { code: string; name: string }[] }[];
  appointmentType: string | null;
  experts: AdhocExpertsProps[];
} | undefined;

export const useAdhocSettings = (): UseAdhocSettingsReturn => {
  const queryResult = useStaticQuery(graphql`
    query fetchAdhocSettings {
      directus {
        adhoc_settings {
          id
          adoc_times {
            slug
          }
          appointment_type
          topics {
            categories_id {
              id
              title
              slug
            }
          }
          languages {
            key
            translations {
              languages_code {
                code
              }
              name
            }
          }
          experts {
            adhoc_settings_experts_id {
              id
              name
              occupation
              image {
                id
                storage
                filename_disk
                filename_download
                type
                title
                width
                height
                filesize
                tags
                imageFile {
                  childImageSharp {
                    gatsbyImageData(width: 430, height: 430, placeholder: NONE, formats: [WEBP])
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const adhocSettings = queryResult.directus.adhoc_settings?.[0];
    if(!adhocSettings) return {}
  return {
    id: adhocSettings.id,
    adhocTimesFlag: adhocSettings?.adoc_times?.slug,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    topics: adhocSettings.topics.map((c: any) => c.categories_id),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    languages: adhocSettings.languages.map((l: any) => ({
      key: l.key,
      translations: l.translations,
    })),
    appointmentType: adhocSettings.appointment_type,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    experts: adhocSettings.experts.map((e: any) => ({
      ...e.adhoc_settings_experts_id,
      image: parseGatsbyImage(e.adhoc_settings_experts_id.image),
    })),
  };
};
