import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const botId = '252ede1a-b404-4bff-972c-74650c61309a'; //process.env.CHAT_BOT_ID;
const botUrl = 'https://botbuilder.siemens.cloud'; //process.env.CHAT_BOT_URL;
type ShowOptions = 'mobile' | 'all-not-mobile';

const waitForBotanicaWebchat = async () => {
  const sleep = (duration) => new Promise((resolve) => setTimeout(resolve, duration));

  for (let i = 0, l = 10000; i < l; i++) {
    if (window['BotanicaWebchat']) {
      try {
        await setup(botId);
      } catch (error) {}
      return;
    }
    await sleep(10);
  }

  throw new Error('BotanicaWebchat did not load in time');
};

export const SiemensChatBot: React.FC<{}> = ({
  showOn,
  align = 'left',
  hidden = false,
  onOpenState,
}) => {
  const [hydrated, setHydrated] = React.useState(false);

  React.useEffect(() => {
    setHydrated(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      injectBotBuilder().then(async () => {
        await setup("252ede1a-b404-4bff-972c-74650c61309a");
      });
    }, 500);
  
   
  }, [])
  

  return (
    <>
      <Helmet> <script src="/chatbot/chatbot.js" />
       
      </Helmet>
      <div id="botbuilder"></div>
    </>
  );

  return (
    <>
      <Helmet>
        <script>
          {`
      function waitForSetup() {
      console.log("WAIT FOR SETUP")
        if (typeof setup === 'function') {
          setup("${botId}");
        
        } else {
          setTimeout(waitForSetup, 100);
        }
      }
      waitForSetup();
    `}
        </script>
        {hydrated ? (
          <script src={`${botUrl}/channels/public/dist/botanicaWebchat.bundle.js`} />
        ) : null}{' '}
        {hydrated ? <script src={`${botUrl}/channels/public/dist/botConfig.js`} /> : null}{' '}
        {hydrated ? (
          <link rel="stylesheet" href={`${botUrl}/channels/public/dist/styles.css`} />
        ) : null}
      </Helmet>
      <div className="botbuilder_spinner" />
      <div
        id="botbuilder"
        /*  className={`${showOn} ${align} ${hidden ? 'hidden' : ''}`}  */
        ref={botDivRef}></div>
    </>
  );
};
